import $, { removeData } from "jquery";
import "bootstrap";
import "bootstrap-icons/font/bootstrap-icons.css";
import { Environment, Terrain, Colors, CanvasRenderer, Agent} from "flocc";

/* ----- PARAMETERS ----- */

const PROP_CULTURAL_HUBS = 0.00;
const PROP_AGENTS_MINORITYLANG = 0.2; // Dutch
const USAGE_MINORITYLANG_AGENTS0 = 0.1;
const USAGE_MINORITYLANG_AGENTS1 = 0.9;
const LEARNING_RATE = 0.1;
const EXPLORATION_PROB = 0.5;
const CULTURAL_CENTER_COLOUR = Colors.PURPLE;
let logging = false;

let [width, height] = [650, 650]; //window.innerwidth/innerheight
/*const scale = (Math.min(width, height) / 50) | 0;
while (width % scale !== 0) width++;
while (height % scale !== 0) height++;*/
const scale = 50;
const scaled_width = width / scale;
const scaled_height = height / scale;



/* ----------------------- */
// Create global variables, will be filled by setup()
let environment = null;
let renderer = null;
let terrain = null;
let requestId = undefined;



function update(updateAgent,utterance) {
  const curValue = alpha_to_p(updateAgent.a);
  updateAgent.a = p_to_alpha(LEARNING_RATE * utterance + (1-LEARNING_RATE)*curValue);
  terrain.set(updateAgent.x, updateAgent.y, updateAgent.r, updateAgent.g, updateAgent.b, updateAgent.a);
}

function p_to_alpha(p) {
  return p * 255;
}

function alpha_to_p(alpha) {
  return alpha / 255;
}

function p_to_colour(p) {
  return { r: 0, g: 0, b: 255, a: p_to_alpha(p) };
}

function colour_to_p(colour) {
  return colour.a / 255;
}

function areSame(p1, p2) {
  return p1.r === p2.r; // && p1.g === p2.g && p1.b === p2.b && p1.a === p2.a;
}

function isCulturalCenter(agent) {
  return areSame(agent, CULTURAL_CENTER_COLOUR);
}

function getNeighboursCoords(x,y) {
  const radius=1;
  const coords = []
  for (let ny = -radius; ny <= radius; ny++) {
    for (let nx = -radius; nx <= radius; nx++) {
      if (nx === 0 && ny === 0) continue;
      coords.push({x:x+nx,y:y+ny});
    }
  }
  const neighbours = terrain.neighbors(x, y, radius, true);
  const neighboursCoords = neighbours.map(function(n,i) {
    n.x = coords[i].x;
    n.y = coords[i].y;
    return n;
  });
  
  const neighboursCoordsNotCultural = neighboursCoords.filter(n=> !isCulturalCenter(n))
  // if (neighboursCoords.length > neighboursCoordsNotCultural.length) {
  //   console.log("Normal" + JSON.stringify(neighboursCoords));
  //   console.log("Not cultural" + JSON.stringify(neighboursCoordsNotCultural));
  // }
  return neighboursCoordsNotCultural;
}

function setupSimulation() {
  environment = new Environment({ width, height });
  renderer = new CanvasRenderer(environment, {
    width,
    height
  });


  terrain = new Terrain(scaled_width, scaled_height, {
    async: true,
    scale: scale
  });
  environment.use(terrain);

  renderer.mount("#container");
  terrain.init((x, y) => {
    let propCulturalHubsVal = $("#culturalHubsSlider").val();
    let propMinorityVal = $("#propMinoritySlider").val();

    if (Math.random() < propCulturalHubsVal) {
      // Create cultural center
      return CULTURAL_CENTER_COLOUR;
    }
    else {
      // Create agent
      if (Math.random() < propMinorityVal) {
        //console.log("Agent lang 1");
        return p_to_colour(USAGE_MINORITYLANG_AGENTS1);
      }
      else {
        //console.log("Agent lang 0");
        return p_to_colour(USAGE_MINORITYLANG_AGENTS0);
      }
    }
  });

  terrain.addRule(function(x, y) {
    // Determine language for today
    const thisAgent = terrain.sample(x,y);
    const thisAgentP = colour_to_p(thisAgent);
    const languageToday = Math.random() < thisAgentP ? 1 : 0;

    // Determine neighbours, excluding cultural centers, and their coordinates to access them
    const neighboursCoords = getNeighboursCoords(x,y);

    if (isCulturalCenter(thisAgent)) {
      // If this agent is a cultural center, let is speak the minority language (Dutch, 1) to all its neighbours
      neighboursCoords.forEach(function(n) {
        update(n,1);
      });
    }
    else {
      if (Math.random() < EXPLORATION_PROB) {
        // Exploration
        // Choose one neighbour and update them with the language we speak with today
        const randomNeighbour = neighboursCoords[Math.floor(Math.random()*neighboursCoords.length)];
        update(randomNeighbour, languageToday);
      }
      else {
        // Exploitation
        // Find neighbour most like today's lanugage, and talk to that neighbour.
        const neighboursPs = neighboursCoords.map(n=>colour_to_p(n));
        const distsLanguageToday = neighboursPs.map(v=>Math.abs(languageToday-v)); // which is closest to language of today?
        const iMax = distsLanguageToday.indexOf(Math.max(...distsLanguageToday));
        const closestNeighbour = neighboursCoords[iMax];
        update(closestNeighbour, languageToday);
      }
    }

    if (logging===true) {
      console.log(neighboursCoords);
      logging=false;
    }
  });

}

function runSimulation() {
  environment.tick();
  requestId = window.requestAnimationFrame(runSimulation);
}

function setupRunSimulation() {
  setupSimulation();
  runSimulation();
}


$(document).ready(function () {

  // Set sliders to default values
  $("#propMinoritySlider").val(PROP_AGENTS_MINORITYLANG);
  $("#culturalHubsSlider").val(PROP_CULTURAL_HUBS);

  // Update slider value text net to it continuously
  $("#propMinoritySlider").on("input", function() {
    let textVal = ($("#propMinoritySlider").val() * 100).toFixed(0)+"%";
    $("#propMinoritySliderText").text(textVal);
  });
  $("#culturalHubsSlider").on("input", function() {
    let textVal = ($("#culturalHubsSlider").val() * 100).toFixed(0)+"%";
    $("#culturalHubsSliderText").text(textVal);
  });
  // Trigger change, so text is shown
  $("#propMinoritySlider").trigger("input");
  $("#culturalHubsSlider").trigger("input");
  

  // 
  $( "#runButton" ).on("click", function() {
    $("#runButton").prop("disabled", true)
    $("#stopButton").prop("disabled", false)
    $("#propMinoritySlider").prop("disabled", true);
    $("#culturalHubsSlider").prop("disabled", true);
    setupRunSimulation();
  });
  $( "#stopButton" ).on("click", function() {
    window.cancelAnimationFrame(requestId);
    $("#runButton").prop("disabled", false)
    $("#stopButton").prop("disabled", true)
    $("#propMinoritySlider").prop("disabled", false);
    $("#culturalHubsSlider").prop("disabled", false);
  });

  // setup simulation after setting slider defaults
  setupSimulation();

  // $( "#reloadButton" ).on("click", function() {
  //   window.location.reload();
  //   return false;
  // });
});
